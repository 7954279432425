<template>
  <div class="o-box--xl flow width:25">
    <h1>{{ $t('notfound.title') }}</h1>
    <p>{{ $t('notfound.content') }}</p>
    <div class="o-wrap--right margin-top:l">
      <b-button @click="goBack" class="c-btn--secondary">{{ $t('notfound.back') }}</b-button>
      <router-link class="c-btn--primary margin-left:base" :to="{ name: 'Auth'}">{{ $t('notfound.login') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack: function () {
      window.history.back()
    }
  }
}
</script>
