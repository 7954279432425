<template>
  <div>
      <select
        :value="selectedValue"
        @change="handleInput"
      >
        <option
          v-if="!selectedValue"
          value=""
        >
        {{ $t('masquerade.please_select') }}
        </option>
        <option
          v-for="item in list"
          :key="item.id"
          :value="item[valueProperty]"
        >
          {{ item[displayProperty] }}
        </option>
      </select>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    list: {
      type: Array,
      default: () => ([])
    },
    listName: {
      type: String,
      default: 'list'
    },
    valueProperty: {
      type: String,
      default: 'id'
    },
    displayProperty: {
      type: String,
      default: 'name'
    }
  },
  data () {
    return {
      dataValue: ''
    }
  },
  async mounted () {
  },
  computed: {
    selectedValue () {
      return this.value[this.valueProperty] || ''
    }
  },
  methods: {
    handleInput (event) {
      this.$emit('handleInput', event)
    }
  }
}
</script>

<style scoped>
  select {
    height: 40px;
    width: 100%;
    margin-bottom: 16px;
    color: #363636;
    border-radius: 4px;
    font-weight: bold;
    padding-left: 8px;
  }
</style>
