export default {
  labels: {
    email: 'Email',
    password: 'Password',
    confirm: 'Confirm password'
  },
  general: {
    email_ph: 'Enter email address',
    password_ph: 'Enter password',
    confirm_ph: 'Confirm password'
  },
  errors: {
    invalid_challenge: 'Invalid or no challenge sent',
    server_error: 'Unable to connect to the auth server',
    invalid_key: 'The code provided has either expired or is invalid',
    invalid_credentials: 'The username and password combination was not correct',
    unauthorized: 'Invalid authorization token',
    invalid_recaptcha: 'Invalid recaptcha key',
    unexpected_error: 'An unexpected error occurred, please try again',
    invalid_confirm: 'The passwords provided do not match'
  },
  login: {
    title: 'Login',
    forgotten: 'Forgot password',
    remember: 'Remember me',
    login: 'Login',
    reset: 'Successfully reset password, please log in to continue'
  },
  masquerade: {
    masquerade: 'Masquerade',
    select_tenant: 'Select your tenant',
    select_subject: 'Select your user',
    please_select: 'Please select...',
    or: 'OR'
  },
  consent: {
    auth: 'Logged in as {user}',
    logout: 'not you?',
    request: '<b>{client}</b> is requesting access to Cauldron on your behalf',
    scope: 'The following permissions will be granted:',
    remember: 'Do not ask me again',
    accept: 'Accept',
    reject: 'Reject'
  },
  error: {
    title: 'Something went wrong!',
    content: 'We can\'t authenticate you at the moment as a configuration error has occurred, please contact the Administrator providing the details below as a reference of the error.'
  },
  forgot: {
    title: 'Forgotten password',
    content: 'Enter your email below and if you have an account with us you will recieve a password reset link via Email',
    success: 'If there is an account associated with the provided email please check you email for the password reset link',
    reset: 'Reset'
  },
  reset: {
    title: 'Reset your password',
    reset: 'Reset'
  },
  notfound: {
    title: 'Page not found...',
    content: 'The requested page doesn\'t exist, click the link below if you were trying to find the authentication page.',
    login: 'Login',
    back: 'Back'
  }
}
