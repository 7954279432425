import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import Cookies from 'vue-cookies'
import VueI18n from 'vue-i18n'
import en from './lang/en'

// Styles
import 'buefy/dist/buefy.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

import './assets/css/main.css'

Vue.use(Buefy)
Vue.use(Cookies)
Vue.use(VueI18n)

// Language
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
