<template>
  <div class="o-box--xl flow width:25">
    <h1 class="text-align:centre margin-bottom:l">{{ $t('error.title') }}</h1>
    <b-notification
      type="is-danger"
      :has-icon="true"
      icon-pack="fa"
      :closable="false"
      role="alert">
      <p>{{ description }}</p>
      <h2>Hint</h2>
      <p>{{ hint }}</p>
    </b-notification>

    <p>{{ $t('error.content') }}</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: this.$route.query.error,
      description: this.$route.query.error_description,
      hint: this.$route.query.error_hint,
      showMore: false
    }
  }
}
</script>
