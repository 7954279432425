<template>
<div>
    <div v-show="loginOrMasquerade" class="masquerade-selection">
      <b-button class="c-btn--primary" :loading="loading" :disabled="loading" @click="masquerade(false)">{{ $t('login.login') }}</b-button>
      {{ $t('masquerade.or') }}
      <b-button class="c-btn--secondary" :disabled="loading" @click="masquerade(true)">{{ $t('masquerade.masquerade') }}</b-button>
    </div>
    <div v-show="!loginOrMasquerade">
      <h4 v-if="showTenants && tenants.length > 1">{{ $t('masquerade.select_tenant') }}</h4>
      <h4 v-if="showTenants && tenants.length === 1" class="tenant-name">{{ selectedTenant.name }}</h4>
      <SelectionBox
        v-if="showTenants && tenants.length > 1"
        :list="tenants"
        list-name="tenants"
        :value="selectedTenant"
        value-property="tenant_id"
        @handleInput="handleTenantSelection"
      />
      <div v-if="!showTenants || fetchingSubjects">
        <img srcset="@/assets/images/loading.gif" alt="loading">
      </div>
      <h4 v-if="selectedTenant && !fetchingSubjects">{{ $t('masquerade.select_subject') }}</h4>
      <SelectionBox
        v-if="selectedTenant && !fetchingSubjects"
        :list="subjects"
        list-name="subjects"
        :value="selectedSubject"
        display-property="display_name"
        @handleInput="handleSubjectSelection"
      />
      <div class="o-wrap--right" v-if="selectedSubject">
        <b-button class="c-btn--primary" native-type="submit" :loading="loading" :disabled="loading" @click="login(true)">{{ $t('login.login') }}</b-button>
      </div>
    </div>
</div>
</template>

<script>
import masquerading from '../service/masquerading'
import SelectionBox from './SelectionBox.vue'

export default {
  props: {
    redirect: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      tenants: [],
      subjects: [],
      loginOrMasquerade: false,
      showTenants: false,
      fetchingSubjects: false,
      loading: false,
      selectedTenant: false,
      selectedSubject: false
    }
  },
  async mounted () {
    await masquerading.getUserDetails()
    const tenants = await masquerading.getTenants()
    this.tenants = tenants
    if (tenants.length === 1) {
      const selectedTenant = tenants[0]
      if (!selectedTenant.permissions.can_masquerade) {
        this.fetchingSubjects = true
        this.login()
        return
      } else {
        this.loginOrMasquerade = true
      }
      this.selectedTenant = selectedTenant
      this.getSubjects(this.selectedTenant.tenant_id)
    }
    this.showTenants = true
  },
  methods: {
    async login (subject = false) {
      this.loading = true
      if (subject) {
        await masquerading.masquerade(this.selectedSubject.id, this.selectedSubject.email)
      }
      window.location = this.redirect()
    },
    masquerade (masquerade) {
      if (masquerade) {
        this.loginOrMasquerade = false
      } else {
        this.login()
      }
    },
    async handleTenantSelection (event) {
      const tenantId = event.target.value
      const selectedTenant = this.tenants.find(tenant => tenant.tenant_id === tenantId)
      this.selectedTenant = selectedTenant
      this.selectedSubject = false
      if (selectedTenant.permissions.can_masquerade) {
        this.loginOrMasquerade = true
        this.getSubjects(tenantId)
      } else {
        this.fetchingSubjects = true
        await masquerading.tenantLogin(tenantId)
        this.login()
      }
    },
    handleSubjectSelection (event) {
      const subjectId = event.target.value
      const selectedSubject = this.subjects.find(subject => subject.id === subjectId)
      this.selectedSubject = selectedSubject
    },
    async getSubjects (tenantId) {
      if (!tenantId) return
      this.fetchingSubjects = true
      await masquerading.tenantLogin(tenantId)
      const subjects = await masquerading.getSubjects()
      this.fetchingSubjects = false
      this.subjects = subjects
    }
  },
  components: { SelectionBox }
}
</script>

<style scoped>
.tenant-name {
  font-weight: 700;
  margin-bottom: 8px;
}
.masquerade-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
img {
  width: 40px;
  margin: 0 auto;
  display: block;
}
</style>
