import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Password from '../views/Password.vue'
import Forgotten from '../views/Forgotten.vue'
import ErrPage from '../views/Error.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Auth',
    component: Login
  },
  {
    path: '/password-reset',
    name: 'Reset',
    component: Password
  },
  {
    path: '/forgotten-password',
    name: 'ForgotPass',
    component: Forgotten
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
]

const router = new VueRouter({
  routes: routes
})

export default router
