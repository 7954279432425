import axios from 'axios'
import Vue from 'vue'

const payloadMethods = ['put', 'post', 'patch']
// axios.defaults.withCredentials = true
const $axios = axios.create()

const masquerading = {
  user: false,

  request: async function (method, endpoint, payload = {}) {
    const baseEndpoint = process.env.VUE_APP_IAM_URL
    let url = ''
    if (!endpoint.match(/http[s]?:\/\//g)) {
      url = `${baseEndpoint}/${endpoint}`
    } else {
      url = endpoint
    }

    $axios.interceptors.request.use(config => {
      if (process.env.NODE_ENV === 'development') {
        const jwt = Vue.$cookies.get('token')
        config.headers = {
          authorization: `Bearer ${jwt}`
        }
      }

      return config
    })

    const config = {
      withCredentials: true,
      headers: {}
    }

    try {
      const { data, user } = await $axios[method](
        url,
        payloadMethods.includes(method) ? payload : config,
        config
      )

      return data || user
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  },

  getUserDetails: async function () {
    const data = await this.request('get', 'subjects/me')
    this.user = data
    return this.user
  },

  getTenants: async function () {
    if (!this.user) return
    try {
      const resp = await this.request('get', `subjects/${this.user.id}/tenants`)

      return resp
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  },

  tenantLogin: async function (tenantId) {
    if (!tenantId) return
    try {
      const resp = await this.request('post', 'subjects/tenantlogin', { tenant_id: tenantId })
      if (process.env.NODE_ENV === 'development') {
        Vue.$cookies.set('token', resp.token)
      }

      return resp
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  },

  getSubjects: async function () {
    if (!this.user) return
    try {
      const resp = await this.request('get', 'tenants/current/subjects')

      return resp
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  },

  masquerade: async function (subjectId, subjectEmail) {
    if (!subjectId || !subjectEmail) return
    try {
      const resp = await this.request('post', 'subjects/masquerade', {
        id: subjectId,
        email: subjectEmail
      })
      if (process.env.NODE_ENV === 'development') {
        Vue.$cookies.set('token', resp.token)
      }

      return resp
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  }
}

export default masquerading
