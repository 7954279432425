<template>
  <div class="o-box--xl flow width:25">
    <b-notification
      v-if="message.content"
      :type="message.type"
      role="alert"
      :closable="false"
      :has-icon="true"
      icon-pack="fa"
    >
      {{ message.content }}
    </b-notification>
    <p>{{ $t('forgot.content') }}</p>
    <form @submit="submit" class="flow">
      <b-field :label="$t('labels.email')" label-for="email-forgotten">
        <b-input type="email" :required="true" v-model="email" :placeholder="$t('general.email_ph')" id="email-forgotten">
        </b-input>
      </b-field>

      <VueRecaptcha
        ref="captcha"
        @expired="captchaExpired"
        @verify="captchaVerified"
        :sitekey="recaptchaKey"
        :loadRecaptchaScript="true"
      >
      </VueRecaptcha>

      <div class="o-wrap--right">
        <b-button class="c-btn--primary" native-type="submit" :disabled="loading || disabled" :loading="loading">{{ $t('forgot.reset') }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '../service/auth'
import VueRecaptcha from 'vue-recaptcha'

export default {
  data () {
    return {
      loading: false,
      disabled: true,
      email: this.$route.query.email,
      recaptchaKey: '6LdYtEAlAAAAALYG0IFkaPKW0NGADPOPnGAEwNud',
      captchaRes: null,
      message: {
        type: '',
        content: ''
      }
    }
  },

  components: {
    VueRecaptcha: VueRecaptcha
  },

  methods: {
    captchaVerified: function (e) {
      this.captchaRes = e
      this.disabled = false
    },

    captchaExpired: function () {
      this.$refs.captcha.reset()
    },

    submit: async function (e) {
      this.loading = true
      e.preventDefault()

      const resp = await auth.forgottenPassword(this.email, this.captchaRes)

      if (resp.success) {
        this.success(this.$t('forgot.success'))
      } else {
        this.error(this.$t(resp.err_code))
      }

      this.loading = false
      this.disabled = true
      this.$refs.captcha.reset()
    },

    error: function (text) {
      this.message.type = 'is-danger'
      this.message.content = text
    },

    success: function (text) {
      this.message.type = 'is-success'
      this.message.content = text
    }
  }
}
</script>
