<template>
  <div class="o-box--xl flow width:25">
    <form @submit="submit" class="flow">
      <b-notification
      v-if="error"
      type="is-danger"
      :closable="false"
      role="alert"
      >
        <p>{{ error }}</p>
      </b-notification>

      <b-field :label="$t('labels.password')" label-for="reset-password">
        <b-input type="password" :required="true" v-model="password" :placeholder="$t('general.password_ph')" id="reset-password">
        </b-input>
      </b-field>

      <b-field :label="$t('labels.confirm')" label-for="confirm-password">
        <b-input type="password" :required="true" v-model="confirm" :placeholder="$t('general.confirm_ph')" id="confirm-password">
        </b-input>
      </b-field>

      <div class="text-align:right">
        <b-button class="c-btn--primary" native-type="submit" :disabled="loading" :loading="loading">Reset</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '../service/auth'

export default {
  data () {
    return {
      code: this.$route.query.code,
      error: '',
      password: '',
      confirm: '',
      loading: false
    }
  },

  methods: {
    submit: async function (e) {
      e.preventDefault()

      if (!this.validate()) return

      const result = await auth.resetPassword(this.code, this.password)

      if (result.success) {
        this.$router.push({ name: 'Auth', query: { reset: true } })
      } else {
        this.error = this.$t(result.err_code)
      }
    },

    validate: function () {
      if (this.password !== this.confirm) {
        this.error = this.$t('errors.invalid_confirm')
        return false
      }

      return true
    }
  },

  created: function () {
    if (!this.code) {
      this.$router.push({ name: 'ForgotPass' })
    }
  }
}
</script>
