<template>
  <div class="o-box--xl flow width:25">
    <form @submit="submit" class="flow">
      <b-notification
        v-if="message.content"
        :type="message.type"
        role="alert"
        :closable="false"
        :has-icon="true"
        icon-pack="fa"
      >
        {{ message.content }}
      </b-notification>

      <template v-if="!masqueradeOrLogin">
        <b-field :label="$t('labels.email')" label-for="email">
          <b-input type="email" :required="true" v-model="email" :placeholder="$t('general.email_ph')" id="email">
          </b-input>
        </b-field>

        <b-field :label="$t('labels.password')" label-for="password">
          <b-input type="password" :required="true" v-model="password" :placeholder="$t('general.password_ph')" id="password">
          </b-input>
        </b-field>
        <p ><router-link :to="{ name: 'ForgotPass' }">{{ $t('login.forgotten') }}</router-link></p>

        <div>
          <b-checkbox v-model="remember">
            {{ $t('login.remember') }}
          </b-checkbox>
        </div>
      </template>
      <template v-if="masqueradeOrLogin">
        <MasqueradeOrLogin
          :redirect="getRedirect"
        />
      </template>

      <VueRecaptcha ref="captcha" @expired="captchaExpired" @verify="verifyCaptcha" v-if="failedAttempts >= 5" :sitekey="recaptchaKey" :loadRecaptchaScript="true"></VueRecaptcha>

      <div class="o-wrap--right" v-if="!masqueradeOrLogin">
        <b-button class="c-btn--primary" native-type="submit" :disabled="disabled" :loading="loading">{{ $t('login.login') }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '../service/auth'
import VueRecaptcha from 'vue-recaptcha'
import MasqueradeOrLogin from '../components/MasqueradeOrLogin.vue'

export default {
  data () {
    return {
      email: '',
      password: '',
      remember: false,
      loading: false,
      recaptchaKey: '6LdYtEAlAAAAALYG0IFkaPKW0NGADPOPnGAEwNud',
      token: null,
      message: {
        type: '',
        content: ''
      },
      redirect: this.$route.query.redirect,
      failedAttempts: this.$cookies.get('fAtt'),
      masqueradeOrLogin: false
    }
  },

  components: {
    VueRecaptcha: VueRecaptcha,
    MasqueradeOrLogin
  },

  methods: {
    verifyCaptcha: function (token) {
      this.token = token
    },

    captchaExpired: function () {
      this.$refs.captcha.reset()
    },

    submit: async function (e) {
      e.preventDefault()
      this.loading = true
      const resp = await auth.attempt(this.email, this.password, this.remember, this.token)
      this.loading = false

      if (resp.success) {
        this.$cookies.remove('fAtt')
        // Set a marker to do a pre-flight auth check if remember is true
        if (this.remember) {
          this.$cookies.set('authenticated', 'true')
        }

        this.masqueradeOrLogin = true
        if (process.env.VUE_APP_DEV || process.env.VUE_APP_DEV === 'true') {
          this.$cookies.set('token', resp.data.token)
        }
      } else {
        if (resp.status && resp.status === 400) {
          if (!this.failedAttempts) {
            this.failedAttempts = 1
          } else {
            this.failedAttempts++
          }

          this.$cookies.set('fAtt', this.failedAttempts)
        }

        // handle error
        this.error(this.$t(resp.err_code))
        if (this.failedAttempts >= 5) {
          this.$refs.captcha.reset()
        }
      }
    },

    jwtFlow: async function () {
      const resp = await auth.attempt()

      // We only need to act if the pre-flight is successful
      if (resp.success) {
        window.location = this.getRedirect(resp.data)
      }
    },

    getRedirect: function (body = {}) {
      if (body.redirect) {
        return body.redirect
      }

      if (this.redirect) {
        return this.redirect
      }

      const redirect = this.$cookies.get('redirect')
      if (redirect) {
        this.$cookies.remove('redirect')
        return redirect
      }

      return auth.getRedirect()
    },

    error: function (text) {
      this.message.type = 'is-danger'
      this.message.content = text
      this.clearMessage()
    },

    success: function (text) {
      this.message.type = 'is-success'
      this.message.content = text
      this.clearMessage()
    },

    clearMessage: function () {
      window.setTimeout(() => {
        this.message.content = ''
        this.message.type = ''
      }, 4000)
    }
  },

  computed: {
    disabled: function () {
      if (this.loading) return true
      if (this.failedAttempts >= 5 && !this.token) return true
      return false
    }
  },

  created () {
    if (this.$route.query.reset) {
      this.success(this.$t('login.reset'))
    }

    if (this.redirect) {
      this.$cookies.set('redirect', this.redirect)
    }

    // if theres no challenge but an authentication cookie, try to auth with JWT
    if (this.$cookies.get('authenticated')) {
      this.jwtFlow()
    }
  }
}
</script>
