import axios from 'axios'

axios.defaults.withCredentials = true

const auth = {
  client: axios.create({
    baseURL: process.env.VUE_APP_AUTH_URL || `${window.location.protocol}//${window.location.host.replace('auth.', 'bureau.')}`,
    timeout: 4000,
    headers: {
      'Content-Type': 'application/json'
    }
  }),

  getRedirect: function () {
    const redirect = process.env.VUE_APP_LANDING_URL || `${window.location.protocol}//${window.location.host.replace('auth.', 'app.')}`
    return redirect
  },

  request: async function (method, url, data) {
    try {
      const resp = await this.client({
        method: method,
        url: url,
        data: data
      })

      return { success: true, data: resp.data }
    } catch (e) {
      if (e.response) {
        return { success: false, status: e.response.status, err_code: `errors.${e.response.data.code}` }
      } else {
        return { success: false, err_code: 'errors.unexpected_error' }
      }
    }
  },

  forgottenPassword: async function (email, captcha) {
    return await this.request('post', '/forgotten-password', { email: email, captcha: captcha })
  },

  resetPassword: async function (code, password) {
    return await this.request('post', '/password-reset', { code: code, password: password })
  },

  attempt: async function (email, password, remember, captcha) {
    return await this.request('post', '/attempt', {
      email: email,
      password: password,
      remember: remember,
      captcha: captcha
    })
  }
}

export default auth
