<template>
  <main class="auth container">

    <div class="o-box--l o-centre width:7">
      <img src="@/assets/images/sales-i-logo.svg" alt="sales-i logo">
    </div>

    <div class="o-centre">
      <div class="c-card--form">
        <router-view />
      </div>
      <Footer />
    </div>
  </main>
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  components: {
    Footer: Footer
  }
}
</script>

<style>
.content {
  padding: 10px 20px 10px 30px !important;
}

.full-height {
  height:100vh;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
}

.title {
  margin-top: 10px;
}
</style>
