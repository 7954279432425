<template>
<div class="footer-message">
    © {{ year }} sales.ai - Crafted with <i class="fa fa-heart"></i> in Solihull, UK.
</div>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style>
.footer-message {
    text-align: center;
    font-size: 13px;
    color: #666;
    padding: 2rem 0;
}

.footer-message > i {
    color: #eb5e5e;
}
</style>
